import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { defaultToast, ToastType } from '@/utils/toastUtils'
import { parseConfigs } from '../../../../../utils/utils'
import i18n from '@/plugins/i18n'
export default {
  namespaced: true,
  state: {
    treeRef: null,
    selectedHierarchyNode: null,
    allHierarchyResponse: [],
    hierarchyDetails: null,
    hierarchyBasicDetails: null,
    parentMonitoringMethods: [],
    availableMonitoringMethods: {},
    availableMonitoringMethodsAsOptions: [],
    selectedMonitoringMethods: [],
    basicDetailsEditing: false,
    addNewHierarchConfig: {},
    loginDetails: [],
    typeOfPatientsOptions: [],
    accessTypeOptions: [],
    selectedHierarchyCommunicationConfig: {},
    selectedLanguages: [],
    showLoader: false,
    selectedHierarchyId: null
  },
  mutations: {
    UPDATE_PASSWORD_RESPONSE (state, payload) {

    },
    TOGGLE_LOADER (state) {
      state.showLoader = !state.showLoader
    },
    UPDATE_COMMUNICATION_FOR_SELECTED_HIERARCHY (state, payload) {
    },
    COMMUNICATION_CONFIG_FOR_SELECTED_HIERARCHY (state, payload) {
      state.selectedHierarchyCommunicationConfig = payload
      state.selectedLanguages = payload.selectedLanguages.map(language => language.languageId)
    },
    CREATE_NEW_LOGIN_RESPONSE (state, payload) {
    },
    GET_NEW_LOGIN_CONFIG (state, payload) {
      state.typeOfPatientsOptions = payload.responseTypeOfPatients
      state.accessTypeOptions = payload.responseAccessType
    },
    ADD_NEW_HIERARCHY (state, payload) {
    },
    ADD_HEW_HIERARCHY_CONFIG (state, payload) {
      state.addNewHierarchConfig = { ...payload }
    },
    UPDATE_TREE_REF (state, payload) {
      state.treeRef = payload
    },
    SELECT_NODE (state, payload) {
      state.selectedHierarchyNode = payload.hierarchy
      state.basicDetailsEditing = false
    },
    TOGGLE_BASIC_DETAILS_EDIT (state) {
      state.basicDetailsEditing = !state.basicDetailsEditing
    },
    GET_ALL_HIERARCHIES (state, payload) {
      state.allHierarchyResponse = payload.data
    },
    GET_HIERARCHY_DETAILS (state, payload) {
      state.hierarchyDetails = payload
      state.hierarchyBasicDetails = payload.basicDetails
      state.loginDetails = payload.loginDetails
      state.selectedHierarchyId = state.hierarchyBasicDetails.id
    },
    GET_PARENT_MONITORING_METHODS (state, payload) {
      var parentMonitoringMethodData = []
      if (payload !== null && payload.basicDetails != null) {
        parentMonitoringMethodData = payload.basicDetails.monitoringMethods
      }
      state.parentMonitoringMethods = parentMonitoringMethodData
    },
    UPDATE_BASIC_DETAILS (state, payload) {
      state.hierarchyBasicDetails.name = payload.name
      state.hierarchyBasicDetails.code = payload.code
      state.hierarchyBasicDetails.hasMERM = payload.hasMERM
      state.hierarchyBasicDetails.hasVOT = payload.hasVOT
      state.hierarchyBasicDetails.has99D = payload.has99D
      state.hierarchyBasicDetails.has99DL = payload.has99DL
      state.hierarchyBasicDetails.hasNone = payload.hasNone
      state.basicDetailsEditing = false
    },
    UPDATE_AVAILABLE_MONITORING_METHODS_AND_SELETED_METHODS (state, hasParent) {
      let monitoringMethods = [
        'Merm',
        'VOT',
        'NNDots',
        'NNDotsLite',
        'None'
      ]
      if (hasParent) {
        monitoringMethods = state.parentMonitoringMethods
      }
      state.availableMonitoringMethodsAsOptions = []
      const selectedMonitoringMethods = state.hierarchyBasicDetails.monitoringMethods
      const labelsMap = {
        Merm: 'allow_MERM_registration',
        VOT: 'allow_VOT',
        NNDots: 'allow_99dots',
        NNDotsLite: 'allow_99dotsLite',
        None: 'allow_follow_up_without_tech'
      }
      for (const key of monitoringMethods) {
        state.availableMonitoringMethodsAsOptions.push({ Key: key, Value: labelsMap[key] })
      }
      state.selectedMonitoringMethods = [...selectedMonitoringMethods]
    }
  },
  actions: {
    async updateTreeRef ({ dispatch, commit }, treeRef) {
      commit('UPDATE_TREE_REF', treeRef)
    },
    async getAllHierarchies ({ dispatch, commit }) {
      try {
        const url = 'v1/hierarchies/user'
        const response = await RegistryServerClient.get(url, {}, registryErrorCallback)
        commit('GET_ALL_HIERARCHIES', response)
        return response
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('getAllHierarchies')
        }
      }
    },
    async getHierarchyDetails ({ dispatch, commit }, { hierarchyId }) {
      try {
        const url = 'v1/hierarchy/summary/user'
        const params = { hierarchyId: hierarchyId }
        const response = await RegistryServerClient.get(url, params, registryErrorCallback)
        // eslint-disable-next-line eqeqeq
        if (response.success) {
          commit('GET_HIERARCHY_DETAILS', response.data)
        } else {
          commit('GET_HIERARCHY_DETAILS', null)
          registryErrorCallback('' + response.success)
        }
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('getHierarchyDetails')
        }
      }
    },
    async getParentMonitoringMethods ({ dispatch, commit }, { params }) {
      try {
        const url = 'v1/hierarchy/summary/user'
        console.log(params.hierarchyId)
        if (params.hierarchyId !== undefined) {
          const response = await RegistryServerClient.get(url, params, registryErrorCallback)
          // eslint-disable-next-line eqeqeq
          if (response.success) {
            commit('GET_PARENT_MONITORING_METHODS', response.data)
            commit('UPDATE_AVAILABLE_MONITORING_METHODS_AND_SELETED_METHODS', true)
          } else {
            commit('GET_PARENT_MONITORING_METHODS', null)
            commit('UPDATE_AVAILABLE_MONITORING_METHODS_AND_SELETED_METHODS', false)
          }
        } else {
          commit('GET_PARENT_MONITORING_METHODS', null)
          commit('UPDATE_AVAILABLE_MONITORING_METHODS_AND_SELETED_METHODS', false)
        }
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('getParentMonitoringMethods')
        }
      }
    },
    async updateBasicDetails ({ dispatch, commit }, { data }) {
      try {
        const url = 'v1/hierarchy/user'
        const response = await RegistryServerClient.put(url, data, registryErrorCallback)
        if (response && response.success) {
          commit('UPDATE_BASIC_DETAILS', { data: response.data })
          await dispatch('getAllHierarchies')
          defaultToast(ToastType.Success, i18n.t('update_was_successful'))
        } else if (response !== undefined && response !== null && response.message !== undefined) {
          registryErrorCallback(response.message)
        }
        // eslint-disable-next-line eqeqeq
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('updateBasicDetails')
        }
      }
    },
    toggleBasicDetailsEdit ({ dispatch, commit }) {
      commit('TOGGLE_BASIC_DETAILS_EDIT')
    },
    selectNode ({ dispatch, commit }, { node }) {
      commit('SELECT_NODE', node)
    },
    async getAddNewHierarchyConfig ({ commit, state, dispatch }, { params }) {
      try {
        let childrenTypes
        const url = 'v1/hierarchy/children/type/' + params.hierarchyId
        const childrenTypesResp = await RegistryServerClient.get(url)
        if (childrenTypesResp?.success && childrenTypesResp?.data) {
          childrenTypes = childrenTypesResp.data.childrenHierarchyTypes
        } else {
          defaultToast(
            ToastType.Error,
            'Unable to load child hierarchy type'
          )
        }

        const urlForParentMonitoringMethods = 'v1/hierarchy/summary/user'
        const responseForParentMonitoringMethods = await RegistryServerClient.get(urlForParentMonitoringMethods, { hierarchyId: params.hierarchyId }, registryErrorCallback)
        commit('ADD_HEW_HIERARCHY_CONFIG', { responseForParentMonitoringMethods: responseForParentMonitoringMethods.data, responseForType: childrenTypes })
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('getAddNewHierarchyConfig')
        }
      }
    },
    async getHierarchyConfigValues ({ dispatch, commit }, params) {
      const url = 'v1/config/hierarchy/mappings'

      const hierarchyId = params.hierarchyId
      const configNames = params.configNames

      const payload = {
        hierarchies: {
          [hierarchyId]: configNames
        }
      }

      const response = await RegistryServerClient.post(url, payload, registryErrorCallback)
      const data = response.data
      const configs = data[0].configs
      return parseConfigs(configs)
    },
    async addNewHierarchy ({ dispatch, commit }, { data }) {
      try {
        const url = 'v1/hierarchy'
        const response = await RegistryServerClient.post(url, data, registryErrorCallback)
        if (response.success) {
          commit('ADD_NEW_HIERARCHY', response)
          defaultToast(ToastType.Success, i18n.t('add_hierarchy_success'))
          await dispatch('getAllHierarchies')
        } else {
          registryErrorCallback(response.Error.Message)
        }
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('addNewHierarchy')
        }
      }
    },
    async getNewLoginConfig ({ dispatch, commit }, { params }) {
      try {
        const configNameForTypeOfPatients = 'TypeOfPatient'
        const configNameForAccessType = 'AccessType'
        const configValues = await dispatch('getHierarchyConfigValues', { hierarchyId: params.hierarchyId, configNames: [configNameForTypeOfPatients, configNameForAccessType] })
        commit('GET_NEW_LOGIN_CONFIG', { responseTypeOfPatients: configValues[configNameForTypeOfPatients], responseAccessType: configValues[configNameForAccessType] })
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('getNewLoginConfig')
        }
      }
    },
    async createNewLoginInHierarchy ({ dispatch, commit }, { data }) {
      try {
        const url = 'v1/users'
        const response = await RegistryServerClient.post(url, data, registryErrorCallback)
        if (response.success) {
          commit('CREATE_NEW_LOGIN_RESPONSE', response)
          defaultToast(ToastType.Success, i18n.t('create_login_success'))
          return response
        } else {
          registryErrorCallback(response.result)
        }
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('createNewLogin')
        }
      }
    },
    async updatePasswordForLogin ({ dispatch, commit }, { data }) {
      try {
        const url = 'v1/user/reset-password'
        const response = await RegistryServerClient.post(url, data, registryErrorCallback)
        if (response.success) {
          commit('UPDATE_PASSWORD_RESPONSE', response)
          defaultToast(ToastType.Success, i18n.t('update_was_successful'))
          return response
        } else {
          registryErrorCallback(response.message)
        }
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('updatePasswordForLogin')
        }
      }
    },
    async getCommunicationConfigForHierarchy ({ dispatch, commit }, { params }) {
      try {
        const url = 'v1/engagement/languages'
        const response = await RegistryServerClient.get(url, params, registryErrorCallback)
        commit('COMMUNICATION_CONFIG_FOR_SELECTED_HIERARCHY', response.data)
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('getCommunicationConfigForHierarchy')
        }
      }
    },
    async updateCommunicationForHierarchy ({ dispatch, commit }, { data }) {
      try {
        const url = 'v1/engagement/languages'
        const response = await RegistryServerClient.put(url, data, registryErrorCallback)
        if (response.success) {
          commit('UPDATE_COMMUNICATION_FOR_SELECTED_HIERARCHY', response.data)
          defaultToast(ToastType.Success, i18n.t('update_was_successful'))
        } else {
          registryErrorCallback(response.Error)
        }
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('updateCommunicationForHierarchy')
        }
      }
    },
    toggleLoader ({ dispatch, commit }) {
      commit('TOGGLE_LOADER')
    }
  }
}
