import { isNotNullNorEmpty } from '@/utils/utils'
import { ApiServerClient } from '@/app/shared/store/Api'
export const isPhoneNumberNotStartingWithZero = val => {
  const regex = /^[1-9][0-9]{9}$/
  return regex.test(val)
}
export const isPhoneNumberStartingWithZero = val => {
  const regex = /[0-9]{10}$/
  return regex.test(val)
}
export const isPhoneNumber = val => {
  // const regex = /^(([1-9]{9})|([0][0-9]{9}))$/;
  const regex = /^(([1-9][0-9]{8}))$/
  return regex.test(val)
}
export const isPhoneNumberLengthEleven = val => {
  const regex = /^[1-9][0-9]{10}$/
  return regex.test(val)
}
export const isPhoneNumberBetweenSevenAndEleven = val => {
  const regex = /^[9][0-9]{6,10}$/
  return regex.test(val)
}
// export const isPhoneNumberLength9without0or10with0 = val => {
//   const regex = /^[1-9][0-9]{8}$/
//   return regex.test(val)
// }
export const isFourAlphaNumericCharacters = val => {
  const regex = /^[a-zA-Z0-9]{1,4}$/
  return regex.test(val)
}
export const isTBNumberFormat = val => {
  const regex = /^[0-9]{6}\/[K][K]\/[1-9]{4}\/\d{4}$/g
  return regex.test(val)
}
export const isTBNumberFormatForMYA = val => {
  const regex = /^[0-9]{4}[-][0-9]{4}$/g
  return regex.test(val)
}
export const isOnlyNumber = val => {
  const regex = /^[0-9]+$/g
  const isRegexMatched = regex.test(val)
  return isRegexMatched
}
export const isThreeDigitNumber = val => {
  const regex = /^[0-9]{1,3}$/g
  return regex.test(val)
}
export const isValidLength = (val, allowedMaxLength, allowedMinLength) => {
  const length = val ? val.length : 0
  let validated = true
  if (allowedMaxLength) { validated = validated && isValidMaxLength(length, allowedMaxLength) }
  if (allowedMinLength) { validated = validated && isValidMinLength(length, allowedMinLength) }
  return validated
}

export const isValidMaxLength = (length, maxlength) => length <= maxlength
export const isValidMinLength = (length, minLength) => length >= minLength

export const makeBackendRequest = async (url, val1, val2) => {
  let finalUrl = url.replace('{1}', val1)
  finalUrl = finalUrl.replace('{2}', val2)

  const resp = await ApiServerClient.get(finalUrl)
  return resp.Success
}

export const isOnlyAlphabetAndSpaces = val => {
  if (typeof (val) === 'string') {
    const regex = /^[\p{L}\s]*$/u
    return regex.test(val)
  } else {
    return true
  }
}

export const isEmailId = val => {
  const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i //eslint-disable-line
  return regex.test(val)
}

export const validRequiredInput = val => {
  if (typeof val === 'object') {
    if (Array.isArray(val)) {
      return val !== null && val.length > 0
    } else if (val !== null && Object.keys(val).length === 0) {
      return false
    }
    return val !== null
  }
  return isNotNullNorEmpty(val)
}

// export const isValueSameAs = (val1, val2) => val1 === val2

export const isAgeBetween18and99 = val => {
  const regex = /(([2-9][0-9])|([1][8-9]))$/
  return regex.test(val)
}

export const isAlphaNumeric = val => {
  const regex = /^[a-zA-Z0-9. ]+$/g
  return regex.test(val)
}

export const isStrongPassword = val => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).{8,}$/g
  return regex.test(val)
}

export const isPhoneNumberMustStartWithZero = val => {
  const regex = /^0[0-9]{9}$/
  return regex.test(val)
}

export const isPhoneNumberLengthSevenToNine = val => {
  const regex = /^[1-9][0-9]{6,8}$/
  return regex.test(val)
}

export const isPhoneNumberLengthNine = val => {
  const regex = /^[1-9][0-9]{8}$/
  return regex.test(val)
}

export const isPhoneNumberLengthNinePlus = val => {
  const regex = /^[1-9][0-9]{8,}$/
  return regex.test(val)
}

export const isPhoneNumberLengthTen = val => {
  const regex = /^[1-9][0-9]{9}$/
  return regex.test(val)
}

export const isPhoneNumberMaxLengthEleven = val => {
  const regex = /^[1-9][0-9]{0,10}$/
  return regex.test(val)
}

export const isBetweenZeroAndHundred = val => {
  const regex = /^[1-9][0-9]?$/g
  return regex.test(val)
}

export const isBetweenTenAndHundred = val => {
  const regex = /^(1[1-9]|[2-9]\d)$/g
  return regex.test(val)
}

export const isNumberLengthFive = val => {
  const regex = /^[0-9]{5}$/
  return regex.test(val)
}

export const isTBNumberFormatForUKRandUGAVOT = val => {
  const regex = /^[0-9]{1,6}([-][0-9]){0,1}$/g
  return regex.test(val)
}

export const isMinLengthGreaterThan2 = val => {
  return val.length > 2
}

export const isGreaterThanZero = val => {
  const regex = /^[1-9][0-9]*$/
  return regex.test(val)
}

export const isPhoneNumberLengthNineWithZero = val => {
  const regex = /^[0-9]{9}$/
  return regex.test(val)
}

export const isPhoneNumberLengthEightPlusWithZero = val => {
  const regex = /^[0-9]{8,}$/
  return regex.test(val)
}

export const isPhoneNumberLengthSevenPlusWithoutZero = val => {
  const regex = /^[1-9][0-9]{6,}$/
  return regex.test(val)
}

export const isPhoneNumberLengthNineWithFirstDigitEightOrNine = val => {
  const regex = /^[8-9][0-9]{8}$/
  return regex.test(val)
}

export const isSevenAlphaNumericCharacters = val => {
  const regex = /^[a-zA-Z0-9]{1,7}$/
  return regex.test(val)
}

export const isPhoneNumberLengthTenStartsWithNine = val => {
  const regex = /^[9][0-9]{9}$/
  return regex.test(val)
}

export const isPhoneNumberLengthEightToTenStartsWithNine = val => {
  const regex = /^[9][0-9]{7,9}$/
  return regex.test(val)
}
